<template>
  <div>
    <loading v-if="isLoading" />
    <Error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.store" />

    <div v-else>
      <b-card class="sticky-header-control">
        <b-row>
          <b-col class="d-flex align-items-center" md="6">
            <h3 class="with-separitor m-0 p-0">
              {{ $t("g.addEquipmentCalibrationPlan") }} |
            </h3>
            <b-breadcrumb :items="breadCrumbItems" />
          </b-col>
          <b-col md="6" class="justify-content-end d-flex">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              @click="newCalibration"
            >
              <feather-icon icon="PlusIcon" class="mr-25 custom-feather" />
              <span>{{ $t("g.addItem") }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 ml-1"
              @click.prevent="validateForm"
            >
              {{ $t("g.submit") }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <ValidationObserver ref="AECPForm">
        <b-form>
          <b-card :title="$t('g.calibrationInformations')" class="calibration">
            <b-row
              v-for="(item, index) in form_data.calibration_reports"
              :key="index"
            >
              <b-col md="12">
                <hr />
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('g.title')">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="validationRules.name"
                    :name="`name_${index}`"
                  >
                    <b-form-input
                      v-model="item.name"
                      :name="`name_${index}`"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('g.title')"
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? $t("g.alert.The title field is required")
                        : errors[0]
                    }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('g.file')">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="validationRules.file"
                    :name="`file_${index}`"
                  >
                    <b-form-file
                      :name="`file_${index}`"
                      v-model="item.file"
                      :state="errors.length > 0 ? false : null"
                      :accept="$t('g.Choose_a_file_or_drop_it_here...')"
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <small class="text-danger">{{
                      errors[0]
                        ? $t("g.alert.The file field is required")
                        : errors[0]
                    }}</small>
                  </ValidationProvider>
                  <div class="mt-1">
                    {{ $t("g.Selected_file") + ": " }}
                    {{ item.file ? item.file.name : "" }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group :label="$t('g.description')">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="validationRules.description"
                    :name="`description_${index}`"
                  >
                    <b-form-textarea
                      id="textarea-default"
                      :placeholder="$t('g.description')"
                      v-model="item.description"
                      :name="`description_${index}`"
                      rows="3"
                      :state="errors.length > 0 ? false : null"
                    />

                    <small class="text-danger">{{
                      errors[0]
                        ? $t("g.alert.The description field is required")
                        : errors[0]
                    }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormSelect,
  BFormDatepicker,
  BBreadcrumb,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    vSelect,
    BFormDatepicker,
    Loading,
    Error,
    BBreadcrumb,
    BFormTextarea,
    BFormFile,
    ContentNotView,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form_data: {
        calibration_reports: [{ name: null, description: null, file: null }],
      },
      validationRules: {
        name: { required: true },
        description: { required: true },
        file: { required: true },
        // Add more fields and validation rules as needed
      },
      current_id: null,
      selected: null,
      isLoading: true,
      isError: false,
      nextTodoId: 1,
      instrument_name_options: [],
      months: [
        {
          abbreviation: "Jan",
          name: "January",
        },
        {
          abbreviation: "Feb",
          name: "February",
        },
        {
          abbreviation: "Mar",
          name: "March",
        },
        {
          abbreviation: "Apr",
          name: "April",
        },
        {
          abbreviation: "May",
          name: "May",
        },
        {
          abbreviation: "Jun",
          name: "June",
        },
        {
          abbreviation: "Jul",
          name: "July",
        },
        {
          abbreviation: "Aug",
          name: "August",
        },
        {
          abbreviation: "Sep",
          name: "September",
        },
        {
          abbreviation: "Oct",
          name: "October",
        },
        {
          abbreviation: "Nov",
          name: "November",
        },
        {
          abbreviation: "Dec",
          name: "December",
        },
      ],
      items: [
        {
          id: 1,
          instruemnt_id: null,
          instrument_name: null,
          serial_number: null,
          element_to_be_calibrate: null,
          calibrate_plan_year: [],
          actual_calibration_date: null,
          next_calibration_date: null,
          calibration_tool_name: null,
        },
      ],
      breadCrumbItems: [
        {
          text: this.$t("g.home"),
          to: { name: "Home" },
        },

        {
          text: this.$t("g.addEquipmentCalibrationPlan"),
          active: true,
        },
      ],
      subPermission: {},
    };
  },

  created() {
    // this.getInstrumentsData();
    // console.log(this.$route.params);
    this.current_id = this.$route.params.id;
    // this.isLoading = false;
  },

  beforeMount() {
    setTimeout(() => {
      this.isLoading = false;
      this.isError = false;
      store.dispatch("GET_PERMISSION", "devices");
      this.subPermission = store.state.permissions.sub;
    }, 2000);
  },

  methods: {
    newCalibration() {
      this.form_data.calibration_reports.push({
        title: null,
        description: null,
        file: null,
      });
    },
    removeItem(index) {
      this.form_data.calibration_reports.splice(index, 1);
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    // handleData(data) {
    //   // console.log(data);
    //   if (data) {
    //     data.forEach((item, index) => {
    //       if (item.device_name) {
    //         this.instrument_name_options.push({
    //           instrument_name: item.device_name,
    //           serial_number: item.device_serial_number,
    //           instruemnt_id: item.device_id,
    //           serial_number: item.device_serial_number,
    //         });
    //       }
    //     });
    //   }
    // },

    // assign the selected values in the loop
    // updateOptions(selected, option) {
    //   selected.instrument_name = option.instrument_name;
    //   selected.instruemnt_id = option.instruemnt_id;
    //   selected.serial_number = option.serial_number;
    // },

    validateForm() {
      console.log(this.form_data);
      console.log(this.$refs.AECPForm);
      this.$refs.AECPForm.validate().then((success) => {
        if (success) {
          this.submitData();
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    submitData() {
      // console.log(this.form_data);
      let formData = new FormData();
      for (let item in this.form_data.calibration_reports) {
        formData.append(
          `calibration_reports[${item}][name]`,
          this.form_data.calibration_reports[item].name
        );
        formData.append(
          `calibration_reports[${item}][description]`,
          this.form_data.calibration_reports[item].description
        );
        formData.append(
          `calibration_reports[${item}][file]`,
          this.form_data.calibration_reports[item].file
        );
      }

      this.$http
        .post(`admin/devices/${this.current_id}`, formData, {
          params: { _method: "put" },
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: "EquipmentCalibrationPlanList" });
          });
        })
        .catch((err) => {
              this.makeToast(
                "danger",
                err.respone.data.message,
                this.$t("g.send.errprTitle")
              );
            });
    },
  },
};
</script>

<style>
.calibration hr {
  margin-top: 0;
}
html[dir="rtl"] svg.feather.custom-feather {
  transform: unset !important;
}
</style>
